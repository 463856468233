@font-face {
	font-family: 'Fredoka';
	src:
		local('Fredoka'),
		url('../../assets/fonts/fredoka/Fredoka-Light.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'FredokaBold';
	src:
		local('FredokaBold'),
		url('../../assets/fonts/fredoka/Fredoka-Regular.ttf') format('truetype');
	font-weight: bold;
}

/* Ant Drawer */
.ant-drawer-top > .ant-drawer-content-wrapper {
	top: env(safe-area-inset-top);
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
	bottom: env(safe-area-inset-bottom);
}

/* Ant Modal */
/* #libra-speaking .ant-modal-body {
  border-bottom-left-radius: 0;
} */
/* #libra-listening .ant-modal-body {
  border-top-right-radius: 0;
} */
/* #user-writing .ant-modal-wrap {
  z-index: 1001;
} */
.ant-drawer-mask,
.ant-drawer-header,
.ant-modal-mask,
.ant-modal-header {
	@supports not (
		(-webkit-backdrop-filter: saturate(180%) blur(18px)) or
			(backdrop-filter: saturate(180%) blur(18px))
	) {
		@media (prefers-color-scheme: light) {
			background: rgba(255, 255, 255, 0.3) !important;
		}
		@media (prefers-color-scheme: dark) {
			background: rgba(66, 66, 66, 0.3) !important;
		}
	}
}

/* Ant Message */
.ant-message-top {
	top: calc(8px + env(safe-area-inset-top)) !important;
}

/* Ant Image Component */
.ant-image-mask {
	border-radius: 6px;
}
.ant-image-preview-mask {
	-webkit-backdrop-filter: saturate(180%) blur(18px);
	backdrop-filter: saturate(180%) blur(18px);
}
.ant-image-preview-close {
	/* top: 11px; */
	top: 8px;
	/* right: 11px; */
	right: 8px;

	@media (min-width: 384px) and (max-width: 512px) {
		right: calc((100vw - 384px) / 2 + 8px);
	}

	padding: 15px;

	-webkit-backdrop-filter: saturate(180%) blur(18px);
	backdrop-filter: saturate(180%) blur(18px);
}
.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
	width: 48px;
	height: 48px;
}
.ant-image-preview-switch-right {
	inset-inline-end: 8px;

	@media (min-width: 384px) and (max-width: 512px) {
		inset-inline-end: calc((100vw - 384px) / 2 + 8px);
	}
}
.ant-image-preview-switch-left {
	inset-inline-start: 8px;

	@media (min-width: 384px) and (max-width: 512px) {
		inset-inline-start: calc((100vw - 384px) / 2 + 8px);
	}
}
.ant-image-preview-footer {
	/* bottom: 10.625px; */
	bottom: 8px;
}
.ant-image-preview-operations {
	padding: 0;

	-webkit-backdrop-filter: saturate(180%) blur(18px);
	backdrop-filter: saturate(180%) blur(18px);
}
.ant-image-preview-operations-operation {
	height: 48px;
	margin-inline-start: 0;
	padding: 15px;
}
